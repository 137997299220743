export const SVG_CONFIG = [
  { svgName: 'add', path: '../assets/icons/add.svg' },
  { svgName: 'bell', path: '../assets/icons/bell.svg' },
  { svgName: 'plus', path: '../assets/icons/plus.svg' },
  { svgName: 'chat', path: '../assets/icons/chat.svg' },
  { svgName: 'date', path: '../assets/icons/date.svg' },
  { svgName: 'note', path: '../assets/icons/note.svg' },
  { svgName: 'menu', path: '../assets/icons/menu.svg' },
  { svgName: 'view', path: '../assets/icons/view.svg' },
  { svgName: 'star', path: '../assets/icons/star.svg' },
  { svgName: 'minus', path: '../assets/icons/minus.svg' },
  { svgName: 'close', path: '../assets/icons/close.svg' },
  { svgName: 'users', path: '../assets/icons/users.svg' },
  { svgName: 'clock', path: '../assets/icons/clock.svg' },
  { svgName: 'deals', path: '../assets/icons/deals.svg' },
  { svgName: 'error', path: '../assets/icons/error.svg' },
  { svgName: 'email', path: '../assets/icons/email.svg' },
  { svgName: 'check', path: '../assets/icons/check.svg' },
  { svgName: 'phone', path: '../assets/icons/phone.svg' },
  { svgName: 'trash', path: '../assets/icons/trash.svg' },
  { svgName: 'export', path: '../assets/icons/export.svg' },
  { svgName: 'orders', path: '../assets/icons/orders.svg' },
  { svgName: 'search', path: '../assets/icons/search.svg' },
  { svgName: 'attach', path: '../assets/icons/attach.svg' },
  { svgName: 'person', path: '../assets/icons/person.svg' },
  { svgName: 'locked', path: '../assets/icons/locked.svg' },
  { svgName: 'compass', path: '../assets/icons/compass.svg' },
  { svgName: 'download', path: '../assets/icons/download.svg' },
  { svgName: 'password', path: '../assets/icons/password.svg' },
  { svgName: 'products', path: '../assets/icons/products.svg' },
  { svgName: 'accounts', path: '../assets/icons/accounts.svg' },
  { svgName: 'patients', path: '../assets/icons/patients.svg' },
  { svgName: 'building', path: '../assets/icons/building.svg' },
  { svgName: 'chat-alt', path: '../assets/icons/chat-alt.svg' },
  { svgName: 'arrow-up', path: '../assets/icons/arrow-up.svg' },
  { svgName: 'headphone', path: '../assets/icons/headphone.svg' },
  { svgName: 'attach-v2', path: '../assets/icons/attach-v2.svg' },
  { svgName: 'checkmark', path: '../assets/icons/checkmark.svg' },
  { svgName: 'logo-icon', path: '../assets/icons/logo-icon.svg' },
  { svgName: 'logo-text', path: '../assets/icons/logo-text.svg' },
  { svgName: 'suppliers', path: '../assets/icons/suppliers.svg' },
  { svgName: 'check-alt', path: '../assets/icons/check-alt.svg' },
  { svgName: 'calculator', path: '../assets/icons/calculator.svg' },
  { svgName: 'arrow-down', path: '../assets/icons/arrow-down.svg' },
  { svgName: 'short-logo', path: '../assets/icons/short-logo.svg' },
  { svgName: 'arrow-left', path: '../assets/icons/arrow-left.svg' },
  { svgName: 'caret-down', path: '../assets/icons/caret-down.svg' },
  { svgName: 'information', path: '../assets/icons/information.svg' },
  { svgName: 'arrow-right', path: '../assets/icons/arrow-right.svg' },
  { svgName: 'circle-dots', path: '../assets/icons/circle-dots.svg' },
  { svgName: 'aim-colored', path: '../assets/icons/aim-colored.svg' },
  { svgName: 'price-sheets', path: '../assets/icons/price-sheets.svg' },
  { svgName: 'download-pdf', path: '../assets/icons/download-pdf.svg' },
  { svgName: 'card-colored', path: '../assets/icons/card-colored.svg' },
  { svgName: 'like-colored', path: '../assets/icons/like-colored.svg' },
  { svgName: 'trash-colored', path: '../assets/icons/trash-colored.svg' },
  { svgName: 'file-download', path: '../assets/icons/file-download.svg' },
  { svgName: 'checkmark-alt', path: '../assets/icons/checkmark-alt.svg' },
  { svgName: 'person-circle', path: '../assets/icons/person-circle.svg' },
  { svgName: 'arrow-left-alt', path: '../assets/icons/arrow-left-alt.svg' },
  { svgName: 'hamburger-menu', path: '../assets/icons/hamburger-menu.svg' },
  { svgName: 'person-colored', path: '../assets/icons/person-colored.svg' },
  { svgName: 'arrow-right-alt', path: '../assets/icons/arrow-right-alt.svg' },
  { svgName: 'warning-colored', path: '../assets/icons/warning-colored.svg' },
  { svgName: 'support-colored', path: '../assets/icons/support-colored.svg' },
  { svgName: 'invoice-summary', path: '../assets/icons/invoice-summary.svg' },
  { svgName: 'arrow-short-left', path: '../assets/icons/arrow-short-left.svg' },
  { svgName: 'arrow-short-right', path: '../assets/icons/arrow-short-right.svg' },
  { svgName: 'arrow-right-filled', path: '../assets/icons/arrow-right-filled.svg' },
  { svgName: 'edit-pencil-colored', path: '../assets/icons/edit-pencil-colored.svg' },
  { svgName: 'price-sheets-search', path: '../assets/icons/price-sheets-search.svg' },
  { svgName: 'synapse-health-logo', path: '../assets/icons/synapse-health-logo.svg' },
  { svgName: 'synapse-health-alt-logo', path: '../assets/icons/synapse-health-alt-logo.svg' },
  { svgName: 'synapse-health-short-logo', path: '../assets/icons/synapse-health-short-logo.svg' },
];
